<template>
  <component
    :is="isCurrent ? 'div' : 'button'"
    class="has-image"
    :class="{ 'is-current' : isCurrent }"
    @click="$emit('click', gee._meta.uid)"
  >
    <Gees :gees="[gee]" imageSize="22" />
    {{ gee.name | getTitle }}
  </component>
</template>

<script>
  import Gees from '@/components/global/Gees';

  export default {
    name: 'IndividualGeeOption',

    components: {
      Gees
    },

    props: {
      gee: {
        type: Object,
        default: null
      },
      isCurrent: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
