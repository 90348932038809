<template>
  <component
    :is="isCurrent ? 'div' : 'button'"
    class="has-image"
    :class="{ 'is-current' : isCurrent }"
    @click="$emit('click', null)"
  >
    <Gees :gees="gees" imageSize="22" />
    {{ title }}
  </component>
</template>

<script>
  import Gees from '@/components/global/Gees';

  export default {
    name: 'AllGeesOption',

    components: {
      Gees
    },

    props: {
      title: {
        type: String,
        default: null
      },
      gees: {
        type: Array,
        default: null
      },
      isCurrent: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
