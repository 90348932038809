<template>
  <Collection
    v-if="!slice.primary.hide_section"
    :title="slice.primary.title | getTitle"
    :subtitle="slice.primary.subtitle"
    :description="slice.primary.description"
    :products="selectedGeeUid ? filteredProducts : products"
  >
    <div v-if="products" class="collection__dropdown">
      <Tooltip :isArrowDropdown="true">
        <template v-slot:trigger>
          <AllGeesOption
            v-if="!selectedGeeUid"
            :gees="geesWithProducts"
            :title="allGeesTitle"
            :isCurrent="true"
          />
          <IndividualGeeOption v-else :gee="selectedGee" :isCurrent="true" />
        </template>
        <template v-slot:content>
          <ul>
            <li>
              <AllGeesOption
                :gees="geesWithProducts"
                :title="allGeesTitle"
                @click="handleDropdownOptionClick($event)"
                :isCurrent="!selectedGeeUid"
              />
            </li>
            <li v-for="gee in geesWithProducts" :key="gee._meta.uid">
              <IndividualGeeOption
                :gee="gee"
                @click="handleDropdownOptionClick($event)"
                :isCurrent="gee._meta.uid == selectedGeeUid"
              />
            </li>
          </ul>
        </template>
      </Tooltip>
    </div>
  </Collection>
</template>

<script>
import { fetchGeePicks } from "@/prismic/queries";

import { shuffleArray } from "@/helpers";

import Collection from "@/components/shop/Collection";
import Tooltip from "@/components/global/Tooltip";
import AllGeesOption from "@/components/slices/sub-components/AllGeesOption";
import IndividualGeeOption from "@/components/slices/sub-components/IndividualGeeOption";
import PrismicLazyImage from "@/components/prismic/PrismicLazyImage";

export default {
  name: "HomepagePicks",

  components: {
    Collection,
    Tooltip,
    AllGeesOption,
    IndividualGeeOption,
    PrismicLazyImage
  },

  data() {
    return {
      gees: null,
      selectedGeeUid: null,
      filteredProducts: null
    };
  },

  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    }
  },

  computed: {
    allGeesTitle() {
      return this.slice.primary.dropdown_title
        ? this.slice.primary.dropdown_title
        : "All Gee Picks";
    },

    geesWithProducts() {
      if (!this.gees) return null;

      return this.gees.filter(gee => {
        return gee?.picks?.products[0]?.product;
        // return (gee.picks && gee.picks.products[0] && gee.picks.products[0].product) ? true : false
      });
    },

    products() {
      if (!this.gees) return null;

      const productsWithDuplicates = this.getProductsWithDuplicates(
        this.geesWithProducts
      );

      const productsWithoutDuplicates = this.processProducts(
        productsWithDuplicates
      );

      return shuffleArray(productsWithoutDuplicates);
    },

    selectedGee() {
      if (!this.selectedGeeUid) return null;

      return this.gees.find(({ _meta }) => _meta.uid == this.selectedGeeUid);
    }
  },

  methods: {
    getProductsWithDuplicates(gees) {
      return gees
        .map(gee => {
          const products = gee.picks.products.slice(0, 6);

          return products.map(({ product }) => {
            if (!product) return null;

            return {
              ...product,
              gees: [gee]
            };
          });
        })
        .flat();
    },

    processProducts(products) {
      const prods = [];
      for (var product of products) {
        const alreadyProcessed = prods.find(item => {
          if (item && product) {
            return item._meta.uid == product._meta.uid;
          }
        });

        if (alreadyProcessed) continue;

        const duplicates = products.filter(item => {
          if (item && product) {
            return item._meta.uid == product._meta.uid;
          }
        });

        if (!duplicates.length) {
          prods.push(product);
          continue;
        }

        product.gees = duplicates.map(prod => prod.gees[0]);
        prods.push(product);
      }

      return prods.filter(v => v !== null);
    },

    handleDropdownOptionClick(geeUid) {
      this.selectedGeeUid = geeUid;

      this.filterProducts(geeUid);
    },

    filterProducts(geeUid) {
      if (!geeUid) {
        this.filteredProducts = this.products;
        return;
      }

      this.filteredProducts = this.products.filter(prod => {
        return prod.gees.find(gee => gee._meta.uid === geeUid);
      });
    }
  },

  async fetch() {
    try {
      const { data } = await this.$apolloProvider.defaultClient.query({
        query: fetchGeePicks
      });

      const response = data.allAuthors.edges;

      if (response.length) {
        this.gees = response.map(item => item.node);
      }
    } catch (err) {
      console.log(err);
    }
  }
};
</script>
